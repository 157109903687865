import React, { FC } from 'react';
import { IContactUs } from './ContactUs.def';
import { renderItems } from '../../utils';
import PageHeader from '../PageHeader';

const ContactUs: FC<IContactUs> = ({ pageHeader, contactCards }) => {
  return (
    <>
      <PageHeader {...pageHeader} />
      <div className="container">
        <div className="contactBlocks">{renderItems(contactCards, 'ContactCard')}</div>
      </div>
    </>
  );
};

export default ContactUs;
