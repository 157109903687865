import React from 'react';
import { PageProps } from 'gatsby';
import Seo from '../../../components/Seo';
import ContactUs from '../../../components/ContactUs/ContactUs';
import chatIcon from '/static/svgs/icon-chat.svg';
import planeIcon from '/static/svgs/category-airline.svg';
import newsIcon from '/static/svgs/category-news.svg';
import businessIcon from '/static/svgs/category-business.svg';
import { getDefaultSEO } from '../../../utils';
import HTPageWrapper from '../../../wrappers/HTPageWrapper';
import { HT_DEFAULT_PAGE_CATEGORY } from '../../../constants';

const ContactPage = ({ location }: PageProps) => {
  const title = 'Contact Us';
  return (
    <HTPageWrapper category={HT_DEFAULT_PAGE_CATEGORY} title={title} location={location}>
      <ContactUs
        pageHeader={{
          title: title,
          subTitle:
            'We want to hear from you and will respond as soon as we can, which is usually within 24-72 hours.',
        }}
        contactCards={[
          {
            title: 'General Feedback & Comments',
            children: (
              <p>
                Email us at <a href="mailto:mail@upgradedpoints.com">mail@upgradedpoints.com</a>
              </p>
            ),
            thumbnail: chatIcon,
            alt: 'Chat Icon',
          },
          {
            title: 'Travel Questions',
            children: (
              <p>
              For the fastest responses, we’d encourage you to leave a comment on a relevant article
              or
                <a
                  href="https://www.facebook.com/groups/LevelUpTravel/"
                  target="_blank"
                  rel="noopener"
                >
                  {' '}
                  join our free travel group
                </a>{' '}
                where you can ask us anything travel-related.
              </p>
            ),
            thumbnail: planeIcon,
            alt: 'Plane Icon',
          },
          {
            title: 'Media Or Advertising Enquiries',
            children: (
              <p>
              Please contact <a href="https://upgradedpoints.com/author/alexmiller/">Alex Miller</a>
              , Founder &amp; CEO of Upgraded Points, directly at the following address:
                <a href="mailto:alex@upgradedpoints.com"> alex@upgradedpoints.com</a>
              </p>
            ),
            thumbnail: newsIcon,
            alt: 'News Icon',
          },
          {
            title: 'Job & Careers',
            children: (
              <p>
                If you’re interested in working for us, please visit our
                <a href="/careers"> Jobs & Careers</a> page to see if there are any positions
                available.
              </p>
            ),
            thumbnail: businessIcon,
            alt: 'Business Icon',
          },
          {
            title: 'Other ways to get in contact',
            variant: 'blue',
            showSocialIcons: true,
            children: (
              <p className="otherways">
                We also have a{' '}
                <a
                  href="https://www.facebook.com/groups/LevelUpTravel/"
                  target="_blank"
                  rel="noopener"
                >
                  Private Facebook Group
                </a>
              </p>
            ),
          },
          {
            title: 'Please Note',
            variant: 'blue',
            children: (
              <p>
                We are unable help with specific airline (including baggage), hotel, credit
              score/report or credit card issues as we are a travel resource 🙂 Please contact those
              companies directly for assistance. Thank you!
              </p>
            ),
          },
        ]}
      />
    </HTPageWrapper>
  );
};

export default ContactPage;

export const Head = ({ location }: PageProps) => {
  const pageTitle = 'Contact Us - UpgradedPoints.com';
  const socialTitle = 'Contact Us'; //TODO
  const ogDescription =
    'We want to hear from the fans and readers of UpgradedPoints.com. Please send us any feedback on updates and improvements!';
  const extraBreadcrumbList = [
    { name: 'About Us', slug: 'about-us' },
    { name: 'Contact Us', slug: 'contact' },
  ];
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    false,
    extraBreadcrumbList,
  );
  return <Seo title={pageTitle} wpSeo={seo} />;
};
